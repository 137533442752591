.pagination {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  list-style: none;
  border-radius: 0.25rem;
  margin-bottom: auto;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff !important ;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #9ea5ac;
  border: 1px solid #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00A18F !important;
  border: 1px solid #00A18F !important;
}

.page-item:disabled li{
  color: #007bff;
  pointer-events: none;
  cursor: auto;
  background-color: #9ea5ac !important ;
  border-color: #dee2e6;
  border: 1px solid #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.react-bs-container-body .table {
  border-collapse: collapse;
}

.react-bs-table .table-bordered {
 
  outline: 0!important;
  color: currentColor;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F3F6F9;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #E7EBEF;
}

.table-striped tbody tr>td{
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.table-striped thead tr>th {
  border-right: 2px solid #fff;
 
}

